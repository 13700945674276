import React, { useEffect, useRef, useState } from "react";
import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import img1 from "../assets/img/img-slider-home.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import trekimg from "../assets/img/img-trek-1.png";
import trekimg2 from "../assets/img/img-trek-2.png";
import { api } from "../conf/apiConf";
import useSWR, { mutate } from "swr";
import LoadingComponent from "../components/LoadingComponent";
import Trek from "../components/Trek";
// import TrekItaliaMaps from "./TrekItaliaMaps";
import MapContainer from "./MapContainer";
import legendDifficulty from "../assets/img/scheda_valutazione.61de7991.jpg";
import _ from "lodash";
import PaginationCustom from "../components/PaginationCustom";
import GoogleMapContainer from "./GoogleMapContainer";
import { useFormik } from "formik";
import TrekFilter from "./TrekFilter";
import moment from "moment";
import "moment/locale/it";

export function dataFormatter(value = "") {
    let date = "";
    if (value === "") {
        date = new Date();
    } else {
        date = value;
    }
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let milliseconds = ("00" + date.getMilliseconds()).slice(-3);

    let formattedDate =
        year +
        "-" +
        month +
        "-" +
        day +
        "T" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        "." +
        milliseconds +
        "Z";
    return formattedDate;
}

const TrekItalia2 = ({ pos = 'Italia', coords = null }) => {

    moment.locale('it')

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [filterUrl, setFilterUrl] = useState({
        position: pos,
        regions: null, //destina<ione
        destination: null,
        typology: [],
        date: moment(),
        dateEnd: null,
        label: null, //classificazione
        departments: [],
        difficulty: [],
        maps: pos === 'Geolocalizzato' || (localStorage.getItem('mapIsActive') === 'true') ? true : false,
        title: '',
        type_trek: null
    })

    //treks api
    const fetcher = async (url) => {
        setLoading(true)
        let loadingIsGettingTooLong = setTimeout(() => {
            if (loading) {
                window.location.reload()
            }
        }, 10000)
        return api.post(url, { ...formik.values, difficulty: formik.values.difficulty?.map(res => res + 1) })
            .then(res => {
                clearTimeout(loadingIsGettingTooLong)
                setLoading(false)
                return res
            }).catch(error => {
                // Gestione dell'errore, se necessario
                setLoading(false)
                console.error("Errore durante la chiamata API:", error);
                throw error;
            })
    };
    const { data: treks, error: errorTreks } = useSWR(
        [`/search-trek?page=${page}`, page],
        fetcher
    );

    //treks point api
    const fetcher2 = (url) =>
        api.post(url, { ...formik.values, difficulty: formik.values.difficulty?.map(res => res + 1) })
            .then((res) => {
                return res
            });
    const { data: treksPoint } = useSWR([`/get-treks-point`], fetcher2);


    //regions api
    const fetcher3 = (url) => api.get(url).then((res) => {
        return res
    });
    const { data: regions } = useSWR([`/regions/118`], fetcher3);

    //states api
    const fetcher7 = (url) => api.get(url).then((res) => {
        return res?.data?.data?.states
    });
    const { data: states } = useSWR([`/filter-list`], fetcher7);

    const fetcher5 = (url) => api.get(url).then((res) => { return res });
    const { data: filter } = useSWR([`/all-formats`], fetcher5);

    //labels api
    const fetcher6 = (url) => api.get(url).then((res) => { return res });
    const { data: label } = useSWR([`/all-label`], fetcher6);

    //departments api
    const fetcher4 = (url) => api.get(url).then((res) => { return res });
    const { data: departments } = useSWR([`/all-departments`], fetcher4);

    const formik = useFormik({
        initialValues: filterUrl,
        onSubmit: (values) => {
            const currentUrl = new URL(window.location.href);
            const searchParams = new URLSearchParams(`date=${values?.date}&dateEnd=${values?.dateEnd}&maps=${values?.maps ? true : false}&type_trek=${values?.type_trek}&destination=${values?.destination ? values?.destination : ''}&difficulty=[${values?.difficulty}]&regions=${values?.regions ? values?.regions : ''}&typology=[${values?.typology}]&departments=[${values?.departments}]&title=${values?.title ? values?.title : ''}&label=${values?.label ? values?.label : ''}&position=${values?.position ? values?.position : 'Italia'}`);
            window.history.replaceState({}, '', `${currentUrl.pathname}?${searchParams.toString()}`);
            mutate([`/search-trek?page=${page}`, page])
            mutate([`/get-treks-point`])
        }
    })

    useEffect(() => {
        if (window.location.search !== '') {
            let url = new URLSearchParams(window.location.search);
            const params = {};
            for (let [key, value] of url.entries()) {

                if (value !== '' && (Number(value) || Number(value) === 0)) {
                    value = Number(value);
                }
                if (value === "null") {
                    value = null;
                }
                if (value === "true") {
                    value = true;
                }
                if (value === "false") {
                    value = false;
                }
                if (value === "undefined") {
                    value = "";
                }
                if (typeof (value) === 'string' && value.includes('[')) {
                    value = JSON.parse(value);
                }
                params[key] = value
            }
            formik.setFieldValue('position', params?.position)
            formik.setFieldValue('label', params && params.label ? params.label : '')
            formik.setFieldValue('regions', params && params.regions ? params.regions : '')
            formik.setFieldValue('destination', params && params.destination ? params.destination : '')
            formik.setFieldValue('typology', params?.typology)
            formik.setFieldValue('date', moment(params?.date).format('yyyy-MM-DD'))
            formik.setFieldValue('dateEnd', params?.dateEnd ? moment(params?.dateEnd).format('yyyy-MM-DD') : null)
            formik.setFieldValue('departments', params?.departments)
            formik.setFieldValue('difficulty', params?.difficulty)
            formik.setFieldValue('maps', params?.maps)
            formik.setFieldValue('title', params?.title)
            formik.setFieldValue('type_trek', params?.type_trek)
            window.scrollTo({
                top: 800,
                left: 0,
                behavior: "smooth",
            });
            formik.submitForm()
        }
    }, [])

    // function checkError() {
    //     if ((data && !data.ok) ||
    //         (treks && !treks.ok) ||
    //         (regions && !regions.ok) ||
    //         (departments && !departments.ok) ||
    //         (filter && !filter.ok) ||
    //         (label && !label.ok) ||
    //         (states && !states.ok))

    //         return <ErrorsComponent />;
    // }
    // if (
    //     !data ||
    //     !treks ||
    //     !regions ||
    //     !departments ||
    //     !filter ||
    //     !label ||
    //     !states
    // )
    //     return <LoadingComponent />;

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={pos === 'Italia' ? "I trek in Italia" : (pos === 'Estero' ? "I trek all'estero" : 'Trek geolocalizzati')}
                img={img1}
                subtitle={pos === 'Italia' ? "Scopri i trekking in partenza nel nostro Bel Paese" : ''}
                position={"center"}
            />
            {
                /* info difficoltà */
                false ? (
                    <div className="transition-shadow shadow-[0_0_6000px_6000px_rgba(33,33,33,0.3)] fixed z-[1] bg-white fixed top-[20px]  left-1/2 -translate-x-[50%] w-[80%] h-[90vh]">
                        <button
                            className="m-2 w-[25px] h-[25px] rounded-full border-[1px] border-black flex justify-center items-center mx-[10px]"
                            onClick={() => {
                                // setInfoDifficolta(false);
                                // setListaFiltri(true);
                                document.body.style.overflow = "auto";
                            }}>
                            X
                        </button>
                        <img src={legendDifficulty} alt="legenda" />
                    </div>
                ) : (
                    ""
                )
            }
            <div
                id="filter"
                className={
                    "2xl:mb-[200px] flex flex-col lg:grid lg:grid-cols-4 py-24 px-8 text-black bg-white"
                }>
                {
                    formik ?
                        <TrekFilter formik={formik ? formik : null} pos={pos} regionsShown={regions?.data?.data} statesShown={states} departments={departments} filter={filter} label={label} allTreks={treksPoint?.data?.data} />
                        :
                        <LoadingComponent />
                }
                <div className="col-span-3" id="main">
                    <h1 className="absolute text-transparent">Risultati ricerca</h1>
                    {!formik.values?.maps ? (
                        <>
                            <div className="hidden lg:grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 lg:grid-rows-3 gap-12 mx-10">
                                {!loading && treks?.data?.data?.data ? treks?.data?.data?.data?.map((res, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Trek
                                                maps={false}
                                                res={res}
                                                img={index % 2 === 1 ? trekimg : trekimg2}
                                                index={index}
                                            />
                                        </React.Fragment>
                                    );
                                })
                                    : (loading ? <LoadingComponent /> : '')}
                            </div>
                            <div className="lg:hidden flex flex-wrap w-full justify-center items-stretch">
                                {!loading && treks?.data?.data?.data ? treks?.data?.data?.data?.map((res, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Trek
                                                maps={false}
                                                res={res}
                                                img={index % 2 === 1 ? trekimg : trekimg2}
                                                index={index}
                                            />
                                        </React.Fragment>
                                    );
                                })
                                    : (loading ? <LoadingComponent /> : '')}
                            </div>
                            {!_.isEmpty(treks?.data?.data?.data) ? (
                                <div
                                    className={
                                        "flex flex-row w-full gap-4 justify-center items-center"
                                    }>
                                    <PaginationCustom page={page} onChange={
                                        (e) => {
                                            setPage(
                                                Number(e.target.textContent) ?
                                                    Number(e.target.textContent)
                                                    : (
                                                        e.target.dataset.testid === "NavigateNextIcon" ?
                                                            (prevPage) => { return (prevPage + 1) }
                                                            : (prevPage) => { return (prevPage - 1) }
                                                    )
                                            )
                                            window.scrollTo(0, 800);
                                        }
                                    } count={treks.data.data['last_page']} />
                                </div>
                            ) : (
                                <div className="text-center text-xl w-full pb-32">
                                    Nessun trek disponibile
                                </div>
                            )}
                        </>
                    ) : (
                        <div
                            className="-translate-y-[3.25%] sticky top-[80px] mt-24 lg:ml-4"
                            style={{ height: "100vh", width: "100%" }}>
                            {
                                pos === 'Italia' ?
                                    <MapContainer zoom={6} treks={treksPoint?.data?.data} />
                                    : (pos === 'Estero' ? <MapContainer zoom={2} treks={treksPoint?.data?.data} /> : <GoogleMapContainer coords={coords} />)
                                // <MapContainer treks={treks.data.data} details={coords ? { longitude_start: coords.longitude, latitude_start: coords.latitude } : null} />
                            }
                        </div>
                    )}
                </div>
            </div>

            <FooterTop />
            <FooterDown />
        </>
    );
};
export default TrekItalia2;
