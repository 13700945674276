import * as React from "react";
import AppBar from "@mui/material/AppBar";
import logo from "../assets/img/Logo.jpg";
import vicinoAme from "../assets/img/icona-geolocal-header.png";
import areaRiservata from "../assets/img/icona-area riservata-header.png";
import lente from "../assets/img/icona-lente-header.png";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api } from "../conf/apiConf";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import useSWR from "swr";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import env from "../conf/variables";
import CryptoJS from "crypto-js";
import DialogCustom from "./DialogCustom";
import InputCustom from "./InputCustom";
import InputBilo from "./InputBilo";
import { useFormik } from "formik";
import { useRef } from "react";
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';


const Topbar = ({ dispatch, count }) => {

    const navigate = useNavigate();
    //   const count = useSelector((state) => state.count);
    const location = useLocation();
    const [errorAssoc, setErrorAssoc] = useState({
        open: false,
        error: "",
    });
    const [openRinnova, setOpenRinnova] = useState(false);
    const [assocType, setAssocType] = useState([]);
    const [errorAssocValue, setErrorAssocValue] = useState(false);
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
    const [today, setToday] = React.useState(new Date())
    const [rinnovaDate, setRinnovaDate] = React.useState(new Date())
    const [search, setSearch] = React.useState("");
    const [minCaratteri, setMinCaratteri] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [anchorEl4, setAnchorEl4] = React.useState(null);
    const [anchorEl5, setAnchorEl5] = React.useState(null);
    const [anchorEl6, setAnchorEl6] = React.useState(null);
    const [anchorEl7, setAnchorEl7] = React.useState([]);
    const [anchorEl8, setAnchorEl8] = React.useState([]);
    const [anchorEl9, setAnchorEl9] = React.useState([]);
    const [anchorEl10, setAnchorEl10] = React.useState([]);
    const [pages, setPages] = useState([])
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const open4 = Boolean(anchorEl4);
    const open5 = Boolean(anchorEl5);
    const open6 = Boolean(anchorEl6);
    const open7 = () => {
        if (anchorEl7) {
            return anchorEl7.map((anchor) => {
                return {
                    name: anchor.name,
                    open: Boolean(anchor.anchor),
                };
            });
        } else {
            return false;
        }
    };
    const open8 = () => {
        if (anchorEl8 && Array.isArray(anchorEl8)) {
            let p = anchorEl8.map((department, index) => {
                return department.length > 0 ? department.map((pages) => {
                    let thisOpen = {
                        name: '',
                        open: false
                    }
                    if (pages.length > 0) {
                        pages.map((pagef) => {
                            if (Boolean(pagef.anchor)) {
                                thisOpen = {
                                    name: pagef.name,
                                    open: Boolean(pagef.anchor),
                                };
                            }
                            return 0
                        })
                    }
                    return thisOpen
                }) : false
            });
            return p
        } else {
            return false;
        }
    };

    const open9 = () => {
        if (anchorEl9) {
            return anchorEl9.map((anchor) => {
                return {
                    name: anchor.name,
                    open: Boolean(anchor.anchor),
                };
            });
        } else {
            return false;
        }
    }
    const open10 = () => {
        if (anchorEl10) {
            return anchorEl10.map((anchor) => {
                return {
                    name: anchor.name,
                    open: Boolean(anchor.anchor),
                };
            });
        } else {
            return false;
        }
    }

    const fetcher3 = (url) => api.get(url).then((res) => {
        dispatch.count.setProfile({
            ...count.profile,
            roles: [...res.data.data.roles]
        })
        return res.data.data
    });
    const { data: user, error: errorUser } = useSWR(
        count.profile ? `/users/get/${count.profile.id}` : null,
        fetcher3
    );

    const fetcher = (url) => api.get(url).then((res) => res);
    const { data: departments } = useSWR([`/all-departments`], fetcher);
    const fetcher2 = (url) => api.get(url).then((res) => res);
    const { data: listPages } = useSWR([`/section/list`], fetcher2);
    count && api.setHeaders({
        Authorization: "Bearer " + count.token,
    });
    const fetcher4 = (url) => api.get(url).then((res) => res);
    const { data: carta } = useSWR([`/users/${count && count.profile && count.profile.id}/carta`], fetcher4);

    function createPagesArray(department) {
        let pagesArray = department ? department.map((page, inddex) => {
            return {
                name: page.title,
                to: '/' + page.id,
                pages: page.children.length > 0 ? createPagesArray(page.children) : []
            }
        }) : []
        return pagesArray
    }

    function anchorsEl7SetNull(prevState) {
        let anchors = prevState;
        departments && departments.data && departments.data.data
            ? (anchors = departments.data.data.map((department, index) => {
                return {
                    name: department.name.includes("Sezione")
                        ? department.name.substring(8)
                        : department.name,
                    anchor: null,
                };
            }))
            : (anchors = prevState);
        return anchors;
    }

    function anchorsEl8SetNull(prevState) {
        let initialValue = []
        let initialArrayValueOld = []
        departments &&
            departments.data &&
            departments.data.data
            ? (departments.data.data.filter((dep) => { return dep.name !== 'Nazionale' }).map((department, index) => {
                let pages = createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[department.id] ? listPages.data.data[department.id] : [])
                initialValue.push(pages.map((page) => {
                    let initialArrayValue = page.pages.map((pagef) => {
                        return {
                            name: page.name,
                            anchor: null,
                        }
                    })
                    return initialArrayValue
                }))
                return 0;
            }))
            : (initialValue = prevState);
        return initialValue;
    }

    function anchorsEl9SetNull(prevState) {
        let anchors = prevState;
        listPages && listPages.data && listPages.data.data && listPages.data.data[1] && listPages.data.data[1].associazione
            ? (anchors = listPages.data.data[1].associazione.map((pageAssoc, index) => {
                return {
                    name: pageAssoc.title,
                    anchor: null,
                };
            }))
            : (anchors = prevState);
        return anchors;
    }

    function anchorsEl10SetNull(prevState) {
        let anchors = prevState;
        listPages && listPages.data && listPages.data.data && listPages.data.data[1] && listPages.data.data[1].trek
            ? (anchors = listPages.data.data[1].trek.map((pageTrek, index) => {
                return {
                    name: pageTrek.title,
                    anchor: null,
                };
            }))
            : (anchors = prevState);
        return anchors;
    }

    useEffect(() => {
        setAnchorEl7((prevState) => {
            let anchors = prevState;
            departments && departments.data && departments.data.data
                ? (anchors = departments.data.data.map((department, index) => {
                    return {
                        name: department.name.includes("Sezione")
                            ? department.name.substring(8)
                            : department.name,
                        anchor: null,
                    };
                }))
                : (anchors = prevState);
            return anchors;
        });

        setAnchorEl8((prevState) => {
            let initialValue = []
            let initialArrayValueOld = []
            departments &&
                departments.data &&
                departments.data.data
                ? (departments.data.data.filter((dep) => { return dep.name !== 'Nazionale' }).map((department, index) => {
                    let pages = createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[department.id] ? listPages.data.data[department.id] : [])
                    initialValue.push(pages.map((page) => {
                        let initialArrayValue = page.pages.map((pagef) => {
                            return {
                                name: page.name,
                                anchor: null,
                            }
                        })
                        return initialArrayValue
                    }))
                    return 0;
                }))
                : (initialValue = prevState);
            return initialValue;
        });
        setAnchorEl9((prevState) => {
            let anchors = prevState;
            listPages && listPages.data && listPages.data.data && listPages.data.data[1] && listPages.data.data[1].associazione
                ? (anchors = listPages.data.data[1].associazione.map((pageAssoc, index) => {
                    return {
                        name: pageAssoc.title,
                        anchor: null,
                    };
                }))
                : (anchors = prevState);
            return anchors;
        });

        setAnchorEl10((prevState) => {
            let anchors = prevState;
            listPages && listPages.data && listPages.data.data && listPages.data.data[1] && listPages.data.data[1].trek
                ? (anchors = listPages.data.data[1].trek.map((pagetrek, index) => {
                    return {
                        name: pagetrek.title,
                        anchor: null,
                    };
                }))
                : (anchors = prevState);
            return anchors;
        });
    }, [departments]);

    const handleClick = (event, set, anchors = null, indexDep = null) => {
        if (anchors && anchors[indexDep] && indexDep !== null) {

            const anchorsCopy = anchors;
            set(() => {
                const newValue = anchorsCopy.map((anchor, index) => {
                    if (index === indexDep) {
                        let newAnchor = anchor.map((val) => {
                            val.map((pagef) => {
                                if (event.target.outerText === pagef.name) {
                                    pagef.anchor = event.currentTarget;
                                } else {
                                    pagef.anchor = null;
                                }
                            })
                            return val;
                        });
                        return newAnchor;
                    }
                    return anchor;
                });
                return newValue;
            });

        } else if (anchors && indexDep === null) {
            const anchorsCopy = anchors;
            set(
                anchorsCopy.map((anchor) => {
                    if (event.target.outerText === anchor.name) {
                        anchor.anchor = event.currentTarget;
                    }
                    return anchor;
                })
            );
        } else {
            set(event.currentTarget);
        }
    };

    const handleClose = (set, anchors = null, child = null) => {
        if (anchors) {
            const anchorsCopy = anchors;
            if (child) {
                set(
                    anchorsCopy.map((department) => {
                        return department.map((anchor) => {
                            anchor.map((anchorf) => {
                                if (anchorf.anchor !== null) {
                                    anchorf.anchor = null;
                                }
                            })

                            return anchor;
                        })
                    })
                );
            } else {
                set(
                    anchorsCopy.map((anchor) => {
                        if (anchor.anchor !== null) {
                            anchor.anchor = null;
                        }
                        return anchor;
                    })
                );
            }
        } else {
            set(null);
        }
    }

    useEffect(() => {
        setRinnovaDate(departments && departments.data && departments.data.data && departments.data.data.length &&
            departments.data.data[0] && new Date(`${departments.data.data[0].open_membership_month}-${departments.data.data[0].open_membership_day}-${today.getFullYear()}`))
    }, [departments && departments.data && departments.data.data])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            assoc_type: {
                name: "-",
                value: 0,
                id: null
            },
        },
    });
console.log(window.location.href, 'fff')

    return (
        <AppBar position="static">
            <a href={`${window.location.href.replaceAll('#main', "").replaceAll('#filter', "")}#main`} className="-indent-[9999px] active:indent-0 focus:indent-0 absolute bg-white text-black">Vai all'area principale</a>
            {
                window.location.href.includes('trek-italia') || window.location.href.includes('trek-estero') ?
                <a href={`${window.location.href.replaceAll('#main', "").replaceAll('#filter', "")}#filter`} className="-indent-[9999px] active:indent-0 focus:indent-0 absolute bg-white text-black">Vai ai filtri</a>
                :
                ''  
            }

            <div className={"flex flex-row items-center bg-[#f0e9e3]"}>
                <Link tabIndex={0} aria-label="home" role="link" to={'/'}>
                    <img
                        alt={"logo Trekking Italia"}
                        src={logo}
                        className={"object-contain w-full cursor-pointer h-24 "}
                    />
                </Link>
                <div className={"flex flex-col items-center w-full ml-6"}>
                    <div
                        className={
                            "flex flex-row items-center justify-between w-full bg-[#4a4137]"
                        }>
                        <div>
                            <div
                                className={
                                    "flex flex-row items-center gap-2 rounded-full border border-gray-300 mx-2 px-12 cursor-pointer hover:opacity-50 relative"
                                }>
                                <img
                                    alt={"lente di ingrandimento"}
                                    src={lente}
                                    className={"object-contain h-6 absolute left-5"}
                                />
                                <TextField
                                    id="search-input"
                                    aria-label="Ricerca globale"
                                    sx={{ input: { color: "white" }, label: { color: "white", paddingLeft: 5 } }}
                                    label={'Ricerca sito'}
                                    name={"search"}
                                    value={search ? search : ''}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            if (search.length > 3) {
                                                localStorage.setItem('search', JSON.stringify(search));
                                                setMinCaratteri(false)
                                                navigate('/search')
                                            } else {
                                                setMinCaratteri(true)
                                            }

                                        }
                                    }}
                                    variant="standard"
                                    InputProps={{ disableUnderline: true }}
                                />
                            </div>
                            {
                                minCaratteri && <div className="text-center text-[#ff5555]">
                                    Inserire minimo 4 caratteri
                                </div>
                            }
                        </div>
                        {/* <div
                            className={
                                "flex flex-row items-center gap-2 p-2 rounded-full border border-gray-300 mx-2 px-24 cursor-pointer hover:opacity-50"
                            }>
                            <img
                                alt={"img"}
                                src={vicinoAme}
                                className={"object-contain h-6"}
                            />
                            <Link to={'/vicino-a-me'}
                                style={{
                                    fontFamily: "bilo",
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                }}>
                                Vicino a me
                            </Link>
                        </div> */}

                        {
                            count.profile ?
                                <button
                                    aria-controls={open3 ? "basic-menu" : undefined}
                                    aria-expanded={open3 ? "true" : false}
                                    onClick={(event) => setAnchorEl3(event.currentTarget)}
                                    className={
                                        "flex flex-row items-center gap-2 p-2 rounded-full border border-gray-300 mx-2 px-24 cursor-pointer hover:opacity-50"
                                    }
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                        fontSize: "1.5em",
                                        whiteSpace: "nowrap",
                                    }}>
                                    {count.profile.name + " " + count.profile.surname}
                                </button>
                                :
                                <div
                                    className={
                                        "flex flex-row items-center gap-2 p-2 rounded-full border border-gray-300 mx-2 px-24 cursor-pointer hover:opacity-50"
                                    }>
                                    <img
                                        alt={"Area utente"}
                                        src={areaRiservata}
                                        className={"object-contain  h-6"}
                                    />
                                    <Link
                                        to={'/login'}
                                        onClick={() => {
                                            localStorage.setItem('from_associa', false)
                                        }}
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                            fontSize: "1.5em",
                                            whiteSpace: "nowrap",
                                        }}>
                                        Area riservata
                                    </Link>
                                </div>
                        }
                        {count.profile ? (
                            <Menu
                                MenuListProps={{ disablePadding: true }}
                                anchorEl={anchorEl3}
                                open={open3}
                                onClose={() => setAnchorEl3(null)}>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    onClick={() => {
                                        setAnchorEl3(null);
                                    }}>
                                    <Link className={'w-full'} to={'/user'}>
                                        Dati Personali
                                    </Link>

                                </MenuItem>
                                {
                                    count && count.profile && count.profile['user_partner'] === '1' && count.profile['user_partner_state'] === 'accepted' &&
                                    <MenuItem
                                        sx={{
                                            backgroundColor: "#ffffff",
                                            padding: 0,
                                            ":hover": {
                                                backgroundColor: "#df5b23"
                                            },
                                            "a": {
                                                color: "#495054",
                                                padding: "6px 16px"
                                            },
                                            ":hover a": {
                                                color: "#ffffff"
                                            }
                                        }}
                                        onClick={() => {
                                            setAnchorEl3(null);
                                        }}>
                                        <Link className={'w-full'} to={'/carta'}>
                                            Tessera associativa
                                        </Link>
                                    </MenuItem>
                                }
                                <MenuItem

                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    onClick={() => {
                                        setAnchorEl3(null);
                                    }}>
                                    <Link className={'w-full'} to={'/ricerche-salvate'}>
                                        Le mie ricerche salvate
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    onClick={() => {
                                        setAnchorEl3(null);
                                    }}>
                                    <Link
                                        className={'w-full'} to={'/iscrizioni-trek'}>
                                        Le mie iscrizioni ai trek
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    onClick={() => {
                                        setAnchorEl3(null);
                                    }}>
                                    <Link className={'w-full'} to={'/effettuati'}>
                                        Trek effettuati
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    onClick={() => {
                                        setAnchorEl3(null);
                                    }}>
                                    <Link className={'w-full'} to={'/iscrizioni-corsi-eventi'}>
                                        Iscrizioni ad eventi/corsi
                                    </Link>
                                </MenuItem>
                                {
                                    count && count.profile && count.profile['user_partner'] === '1' && count.profile['user_partner_state'] === 'accepted' &&
                                    <MenuItem
                                        sx={{
                                            backgroundColor: "#ffffff",
                                            padding: 0,
                                            ":hover": {
                                                backgroundColor: "#df5b23"
                                            },
                                            "a": {
                                                color: "#495054",
                                                padding: "6px 16px"
                                            },
                                            ":hover a": {
                                                color: "#ffffff"
                                            }
                                        }}
                                        onClick={() => {
                                            setAnchorEl3(null);
                                        }}>
                                        {
                                            env.borsellinoFunction &&
                                            <Link className={'w-full'} to={'/borsellino-elettronico'}>
                                                Borsellino elettronico
                                            </Link>
                                        }
                                    </MenuItem>
                                }
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    onClick={() => {
                                        setAnchorEl3(null);
                                    }}>
                                    <Link className={'w-full'} to={'/privacy'}>
                                        Privacy
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        }
                                    }}>
                                    <Link className="w-full" to={'/login'}
                                        onClick={() => {
                                            dispatch.count.setToken(null);
                                            dispatch.count.setProfile(null);
                                            setAnchorEl3(null);
                                        }}>
                                        Esci
                                    </Link>
                                </MenuItem>
                            </Menu>
                        ) : (
                            ""
                        )}
                        {
                            count && count.profile && (count.profile['user_partner'] === '1' || count.profile['user_partner_state'] === 'pending') ?
                                ''
                                : count.profile ?
                                    <Link to={"/register/socio"}
                                        className={
                                            "uppercase bg-[#c44116] p-4 text-md cursor-pointer hover:opacity-50"
                                        }
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                        }}>
                                        ASSOCIATI!
                                    </Link>

                                    : <Link to={'/login'}
                                        onClick={() => {
                                            localStorage.setItem('from_associa', true)
                                        }}
                                        className={
                                            "uppercase bg-[#c44116] p-4 text-md cursor-pointer hover:opacity-50"
                                        }
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                        }}>
                                        ASSOCIATI!
                                    </Link >
                        }

                        {
                            count && count.profile &&
                            (count.profile['user_partner'] === '1' &&
                                count.profile['user_partner_state'] === 'accepted') &&
                            ((today >= rinnovaDate && carta && carta.data && carta.data.data && carta.data.data.year_membership <= today.getFullYear()) ||
                                (carta && carta.data && carta.data.data && carta.data.data.year_membership < today.getFullYear())) &&

                            <button
                                onClick={() => {
                                    api.get("/users/get-association-types").then((res) => {
                                        setAssocType(res.data.data);
                                        return res;
                                    });
                                    setOpenRinnova(true);
                                }}
                                className={
                                    "whitespace-nowrap uppercase bg-[#c44116] p-4 text-md cursor-pointer hover:opacity-50"
                                }
                                id="button_rinnova_tessera"
                                style={{
                                    fontFamily: "bilo",
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                }}>
                                RINNOVA ASSOCIAZIONE
                            </button>
                        }

                        {
                            count && count.profile && count.profile.roles && count.profile.roles.length > 0 &&
                            <button
                                onClick={() => {
                                    // window.location.replace(`${env.url_panel}login?u=${count.profile.username}&p=${count.password}`)
                                    // const axiosConfig = {
                                    //     headers: {
                                    //         Accept: "application/json",
                                    //         "Content-Type": "application/json",
                                    //     },
                                    // };

                                    // const decryptedBytes = CryptoJS.AES.decrypt(count.password, env.secret_key);
                                    // const decryptedPassword = decryptedBytes.toString(CryptoJS.enc.Utf8);

                                    api.get('users/login-panel').then(res => {
                                        let a = document.createElement("a");
                                        document.body.appendChild(a);
                                        a.style = "display: none";
                                        a.href = `${env.url_panel}login?u=${count.profile.username}&p=${count.password}&t=${res.data.data}`;
                                        a.target = '_blank'
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                }}
                                className={
                                    "uppercase bg-[#c44116] p-4 text-md cursor-pointer hover:opacity-50"
                                }
                                style={{
                                    fontFamily: "bilo",
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                }}>
                                GESTIONALE
                            </button>
                        }
                    </div>
                    <div className={"flex flex-row items-center gap-2 w-full h-12 pl-[0.75rem] pr-4"}>
                        <div
                            className={
                                "flex flex-row items-center gap-2 justify-between w-full h-12 text-amber-900 font-bold"
                            }>
                            <button
                                aria-haspopup={true}
                                aria-controls={open2 ? "basic-menu" : undefined}
                                aria-expanded={open2 ? "true" : false}
                                onClick={(event) => { console.log('gg'); handleClick(event, setAnchorEl2) }}
                                className={"hover:text-[#df5b23] cursor-pointer"}>
                                ASSOCIAZIONE
                            </button>
                            <Menu
                                role="menu"
                                MenuListProps={{ disablePadding: true }}
                                anchorEl={anchorEl2}
                                open={open2}
                                onClose={() => { console.log('ff'); handleClose(setAnchorEl2) }}>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link to={'/chi-siamo'} onClick={() => {
                                        console.log('dd')
                                        setAnchorEl2(null);
                                    }}>
                                        Chi siamo
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/valori'}>
                                        I nostri valori
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    aria-haspopup={true}
                                    sx={{
                                        padding: 0,
                                        "&:hover": {
                                            backgroundColor: "#df5b23",
                                        },

                                        "&:hover > div": {
                                            color: "white",
                                        },
                                        "&:focus": {
                                            backgroundColor: "#df5b23",
                                        },

                                        "&:focus > div": {
                                            color: "white",
                                        },
                                    }}>
                                    <button
                                        aria-label="show more"
                                        role="button"
                                        aria-haspopup={true}
                                        aria-controls={open6 ? "basic-menu" : undefined}
                                        aria-expanded={open6 ? "true" : "false"}
                                        onClick={(event) => setAnchorEl6(event.currentTarget)}
                                        id={"progetti"}
                                        className={
                                            "!bg-transparent w-full pl-[16px] pr-[4px] pb-[6px] pt-[6px] flex items-center justify-between"
                                        }>
                                        Progetti
                                        <KeyboardArrowRightIcon />
                                    </button>
                                    <Menu
                                        role="menu"
                                        MenuListProps={{ disablePadding: true }}
                                        anchorEl={anchorEl6}
                                        open={open6}
                                        onClose={() => setAnchorEl6(null)}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MenuItem
                                            tabIndex={0}
                                            sx={{
                                                backgroundColor: "#ffffff",
                                                padding: 0,
                                                ":hover": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                "a": {
                                                    color: "#495054",
                                                    padding: "6px 16px",
                                                    width: '100%'
                                                },
                                                ":hover a": {
                                                    color: "#ffffff"
                                                },
                                                ":focus": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                ":focus a": {
                                                    color: "#ffffff"
                                                }
                                            }}
                                            role="menuitem"
                                        >
                                            <Link onClick={() => {
                                                setAnchorEl6(null);
                                            }} to={'/area-giovani'}>
                                                Area Giovani
                                            </Link>
                                        </MenuItem>
                                        <MenuItem
                                            tabIndex={1}
                                            sx={{
                                                backgroundColor: "#ffffff",
                                                padding: 0,
                                                ":hover": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                "a": {
                                                    color: "#495054",
                                                    padding: "6px 16px",
                                                    width: '100%'
                                                },
                                                ":hover a": {
                                                    color: "#ffffff"
                                                },
                                                ":focus": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                ":focus a": {
                                                    color: "#ffffff"
                                                }
                                            }}
                                            role="menuitem"
                                        >
                                            <Link onClick={() => {
                                                setAnchorEl6(null);
                                            }} to={'/iniziative-per-ambiente'}>
                                                Iniziative per l'ambiente
                                            </Link>
                                        </MenuItem>
                                        {/* <MenuItem
                                        tabIndex={0}
                                            sx={{
                                                backgroundColor: "#ffffff",
                                                padding: 0,
                                                ":hover": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                "a": {
                                                    color: "#495054",
                                                    padding: "6px 16px",
                                                    width: '100%'
                                                },
                                                ":hover a": {
                                                    color: "#ffffff"
                                                },
                                                ":focus": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                ":focus a": {
                                                    color: "#ffffff"
                                                }
                                            }}
                                            >
                                            <Link onClick={() => {
                                                setAnchorEl6(null);
                                            }} to={'/Accoglienza'}>
                                                Accoglienza
                                            </Link>
                                        </MenuItem> */}
                                        <MenuItem
                                            tabIndex={2}
                                            sx={{
                                                backgroundColor: "#ffffff",
                                                padding: 0,
                                                ":hover": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                "a": {
                                                    color: "#495054",
                                                    padding: "6px 16px",
                                                    width: '100%'
                                                },
                                                ":hover a": {
                                                    color: "#ffffff"
                                                },
                                                ":focus": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                ":focus a": {
                                                    color: "#ffffff"
                                                }
                                            }}
                                            role="menuitem"
                                        >
                                            <Link onClick={() => {
                                                setAnchorEl6(null);
                                            }} to={'/trek-solidali'}>
                                                Trek inclusivi e solidali
                                            </Link>
                                        </MenuItem>
                                        <MenuItem
                                            tabIndex={3}
                                            sx={{
                                                backgroundColor: "#ffffff",
                                                padding: 0,
                                                ":hover": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                "a": {
                                                    color: "#495054",
                                                    padding: "6px 16px",
                                                    width: '100%'
                                                },
                                                ":hover a": {
                                                    color: "#ffffff"
                                                },
                                                ":focus": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                ":focus a": {
                                                    color: "#ffffff"
                                                }
                                            }}
                                            role="menuitem"
                                        >
                                            <Link onClick={() => {
                                                setAnchorEl6(null);
                                            }} to={'/formazione'}>
                                                Formazione
                                            </Link>
                                        </MenuItem>
                                    </Menu>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/i-nostri-volontari'}>
                                        I nostri volontari
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/organismi'}>
                                        Organismi
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/statuto-e-regolamenti'}>
                                        Statuto
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/bilancio-sociale'}>
                                        Bilancio sociale
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/bilanci-annuali'}>
                                        Bilanci
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/faq'}>
                                        Faq
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/coperture-assicurative'}>
                                        Assicurazioni
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    tabIndex={0}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px",
                                            width: '100%'
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}
                                    role="menuitem"
                                >
                                    <Link onClick={() => {
                                        setAnchorEl2(null);
                                    }} to={'/Contatti'}>
                                        Contatti
                                    </Link>
                                </MenuItem>

                                {createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[1] ? listPages.data.data[1].associazione : []).map((page, index) => {
                                    let open9Result = open9()
                                    return (
                                        <MenuItem
                                            key={index}
                                            sx={page.pages.length > 0 ?
                                                {
                                                    padding: 0,
                                                    position: "relative",
                                                    "&:hover": {
                                                        backgroundColor: "#df5b23",
                                                    },

                                                    "&:hover > div": {
                                                        color: "white",
                                                    },
                                                    "&:focus": {
                                                        backgroundColor: "#df5b23",
                                                    },

                                                    "&:focus > div": {
                                                        color: "white",
                                                    },
                                                }
                                                :
                                                {
                                                    backgroundColor: "#ffffff",
                                                    padding: 0,
                                                    ":hover": {
                                                        backgroundColor: "#df5b23"
                                                    },
                                                    "a": {
                                                        color: "#495054",
                                                        padding: "6px 16px"
                                                    },
                                                    ":hover a": {
                                                        color: "#ffffff"
                                                    },
                                                    ":focus": {
                                                        backgroundColor: "#df5b23"
                                                    },
                                                    ":focus a": {
                                                        color: "#ffffff"
                                                    }
                                                }
                                            }
                                        >
                                            <div
                                                role="button"
                                                aria-haspopup={true}
                                                aria-controls={
                                                    open9Result[index] ? "basic-menu" : undefined
                                                }
                                                aria-expanded={open9Result[index] ? "true" : false}
                                                onClick={(event) => {
                                                    if (page.pages.length > 0) {
                                                        handleClick(event, setAnchorEl9, anchorEl9)
                                                    } else {
                                                        setAnchorEl2(null);
                                                    }
                                                }}
                                                id={"nazionale-assoc"}
                                                className={
                                                    `!bg-transparent w-full ${page.pages.length > 0 ? 'pl-[16px] pr-[16px] pb-[6px] pt-[6px]' : ''} flex items-center justify-between`
                                                }
                                            >
                                                {page.pages.length > 0 ? page.name :
                                                    <Link className="w-full" to={"/sezioni/Nazionale" + page.to}>
                                                        {page.name}
                                                    </Link>
                                                }
                                                {page.pages.length > 0 ? <KeyboardArrowRightIcon /> : ""}
                                            </div>
                                            {
                                                page.pages.length > 0 ? (
                                                    <Menu
                                                        MenuListProps={{ disablePadding: true }}
                                                        anchorEl={
                                                            anchorEl9 && anchorEl9.length > 0
                                                                ? anchorEl9[index].anchor
                                                                : null
                                                        }
                                                        open={
                                                            open9Result && open9Result.length > 0
                                                                ? open9Result[index].open
                                                                : false
                                                        }
                                                        onClose={() =>
                                                            handleClose(setAnchorEl9, anchorEl9)
                                                        }
                                                        sx={{
                                                            position: "absolute",
                                                            left: () => {
                                                                return document.getElementById("nazionale-assoc")
                                                                    ? getComputedStyle(
                                                                        document.getElementById("nazionale-assoc")
                                                                    ).width
                                                                    : "0px";
                                                            },
                                                            top: "-35px",
                                                        }}>
                                                        <MenuItem
                                                            key={index}
                                                            sx={{
                                                                backgroundColor: "#ffffff",
                                                                padding: 0,
                                                                ":hover": {
                                                                    backgroundColor: "#df5b23"
                                                                },
                                                                "a": {
                                                                    color: "#495054",
                                                                    padding: "6px 16px"
                                                                },
                                                                ":hover a": {
                                                                    color: "#ffffff"
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                setAnchorEl9((prevState) => {
                                                                    return anchorsEl9SetNull(prevState)
                                                                });
                                                                setAnchorEl2(null);
                                                            }}>
                                                            <Link className="w-full" to={"/sezioni/Nazionale" + page.to}>
                                                                {page.name}
                                                            </Link>
                                                        </MenuItem>
                                                        {page.pages.map((pagef, index) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index + `${index}`}
                                                                    sx={{
                                                                        backgroundColor: "#ffffff",
                                                                        padding: 0,
                                                                        ":hover": {
                                                                            backgroundColor: "#df5b23"
                                                                        },
                                                                        "a": {
                                                                            color: "#495054",
                                                                            padding: "6px 16px"
                                                                        },
                                                                        ":hover a": {
                                                                            color: "#ffffff"
                                                                        }
                                                                    }}>
                                                                    <div
                                                                        role="button"
                                                                        aria-haspopup={true}
                                                                        aria-controls={
                                                                            open9Result[index]
                                                                                ? "basic-menu"
                                                                                : undefined
                                                                        }
                                                                        aria-expanded={
                                                                            open9Result[index] ? "true" : undefined
                                                                        }
                                                                        onClick={(event) => {
                                                                            setAnchorEl9((prevState) => {
                                                                                return anchorsEl9SetNull(prevState)
                                                                            })
                                                                            setAnchorEl2(null);
                                                                        }}
                                                                        id={"sezione-pages"}
                                                                        className={
                                                                            "!bg-transparent w-full flex items-center justify-between"
                                                                        }>
                                                                        <Link to={"/sezioni/Nazionale/" +
                                                                            page.name +
                                                                            pagef.to}>
                                                                            {pagef.name}
                                                                        </Link>
                                                                    </div>
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Menu>
                                                ) : (
                                                    ""
                                                )
                                            }
                                        </MenuItem>
                                    )

                                })}
                            </Menu>
                            <button
                                aria-haspopup="true"
                                role="button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-expanded={open ? "true" : false}
                                onClick={(e) => {
                                    handleClick(e, setAnchorEl);
                                }}
                                className={"hover:text-[#df5b23] cursor-pointer"}>
                                I NOSTRI TREK
                            </button>
                            <Menu
                                MenuListProps={{ disablePadding: true }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => {
                                    handleClose(setAnchorEl);
                                }}>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}>
                                    <Link onClick={() => {
                                        handleClose(setAnchorEl);
                                    }} className="w-full" to={"/trek-italia"}>
                                        <button style={{ all: 'unset', width: '100%' }} role="button" tabIndex="0">
                                            In Italia
                                        </button>
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}>
                                    <Link onClick={() => {
                                        handleClose(setAnchorEl);
                                    }} className="w-full" to={"/trek-estero"}>
                                        Estero
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}>
                                    <Link onClick={() => {
                                        handleClose(setAnchorEl);
                                    }}>
                                        Classificazione
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}>
                                    <Link onClick={() => {
                                        handleClose(setAnchorEl);
                                    }}>
                                        Preparati al tuo trek
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}>
                                    <Link
                                        onClick={() => {
                                            localStorage.setItem('accompagnatoreSez', 0)
                                            localStorage.setItem('accompagnatorePage', 1)
                                            handleClose(setAnchorEl);
                                        }} to={"/accompagnatori"}>
                                        Accompagnatori
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}>
                                    <Link onClick={() => {
                                        handleClose(setAnchorEl);
                                    }}>
                                        Dicono di noi
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        padding: 0,
                                        ":hover": {
                                            backgroundColor: "#df5b23"
                                        },
                                        "a": {
                                            color: "#495054",
                                            padding: "6px 16px"
                                        },
                                        ":hover a": {
                                            color: "#ffffff"
                                        },
                                        ":focus": {
                                            backgroundColor: "#df5b23"
                                        },
                                        ":focus a": {
                                            color: "#ffffff"
                                        }
                                    }}>
                                    <Link onClick={() => {
                                        handleClose(setAnchorEl);
                                    }}>
                                        Immagini dai trek
                                    </Link>
                                </MenuItem>
                                {createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[1] ? listPages.data.data[1].trek : []).map((page, index) => {
                                    let open10Result = open10()
                                    return <MenuItem
                                        key={index}
                                        sx={page.pages.length > 0 ?
                                            {
                                                padding: 0,
                                                position: "relative",
                                                "&:hover": {
                                                    backgroundColor: "#df5b23",
                                                },

                                                "&:hover > div": {
                                                    color: "white",
                                                },
                                                "&:focus": {
                                                    backgroundColor: "#df5b23",
                                                },

                                                "&:focus > div": {
                                                    color: "white",
                                                },
                                            }
                                            :
                                            {
                                                backgroundColor: "#ffffff",
                                                padding: 0,
                                                ":hover": {
                                                    backgroundColor: "#df5b23"
                                                },
                                                "a": {
                                                    color: "#495054",
                                                    padding: "6px 16px"
                                                },
                                                ":hover a": {
                                                    color: "#ffffff"
                                                }
                                            }
                                        }>
                                        <div
                                            role="button"
                                            aria-haspopup={true}
                                            aria-controls={
                                                open10Result[index] ? "basic-menu" : undefined
                                            }
                                            aria-expanded={open10Result[index] ? "true" : false}
                                            onClick={(event) => {
                                                if (page.pages.length > 0) {
                                                    handleClick(event, setAnchorEl10, anchorEl10)
                                                } else {
                                                    handleClose(setAnchorEl);
                                                }
                                            }}
                                            id={"nazionale-trek"}
                                            className={
                                                `!bg-transparent w-full ${page.pages.length > 0 ? 'pl-[16px] pr-[16px] pb-[6px] pt-[6px]' : ''} flex items-center justify-between`
                                            }
                                        >
                                            {page.pages.length > 0 ? page.name :
                                                <Link className="w-full" to={"/sezioni/Nazionale" + page.to}>
                                                    {page.name}
                                                </Link>
                                            }
                                            {page.pages.length > 0 ? <KeyboardArrowRightIcon /> : ""}
                                        </div>
                                        {
                                            page.pages.length > 0 ? (
                                                <Menu
                                                    MenuListProps={{ disablePadding: true }}
                                                    anchorEl={
                                                        anchorEl10 && anchorEl10.length > 0
                                                            ? anchorEl10[index].anchor
                                                            : null
                                                    }
                                                    open={
                                                        open10Result && open10Result.length > 0
                                                            ? open10Result[index].open
                                                            : false
                                                    }
                                                    onClose={() =>
                                                        handleClose(setAnchorEl10, anchorEl10)
                                                    }
                                                    sx={{
                                                        position: "absolute",
                                                        left: () => {
                                                            return document.getElementById("nazionale-trek")
                                                                ? getComputedStyle(
                                                                    document.getElementById("nazionale-trek")
                                                                ).width
                                                                : "0px";
                                                        },
                                                        top: "-35px",
                                                    }}>
                                                    <MenuItem
                                                        key={index}
                                                        sx={{
                                                            backgroundColor: "#ffffff",
                                                            padding: 0,
                                                            ":hover": {
                                                                backgroundColor: "#df5b23"
                                                            },
                                                            "a": {
                                                                color: "#495054",
                                                                padding: "6px 16px"
                                                            },
                                                            ":hover a": {
                                                                color: "#ffffff"
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            setAnchorEl10((prevState) => {
                                                                return anchorsEl10SetNull(prevState)
                                                            });
                                                            handleClose(setAnchorEl);
                                                        }}>
                                                        <Link className="w-full" to={"/sezioni/Nazionale" + page.to}>
                                                            {page.name}
                                                        </Link>
                                                    </MenuItem>
                                                    {page.pages.map((pagef, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index + `${index}`}
                                                                sx={{
                                                                    backgroundColor: "#ffffff",
                                                                    padding: 0,
                                                                    ":hover": {
                                                                        backgroundColor: "#df5b23"
                                                                    },
                                                                    "a": {
                                                                        color: "#495054",
                                                                        padding: "6px 16px"
                                                                    },
                                                                    ":hover a": {
                                                                        color: "#ffffff"
                                                                    }
                                                                }}>
                                                                <div
                                                                    role="button"
                                                                    aria-haspopup={true}
                                                                    aria-controls={
                                                                        open10Result[index]
                                                                            ? "basic-menu"
                                                                            : undefined
                                                                    }
                                                                    aria-expanded={
                                                                        open10Result[index] ? "true" : undefined
                                                                    }
                                                                    onClick={(event) => {
                                                                        setAnchorEl10((prevState) => {
                                                                            return anchorsEl10SetNull(prevState)
                                                                        })
                                                                        setAnchorEl2(null);
                                                                    }}
                                                                    id={"sezione-pages"}
                                                                    className={
                                                                        "!bg-transparent w-full flex items-center justify-between"
                                                                    }>
                                                                    <Link to={"/sezioni/Nazionale/" +
                                                                        page.name +
                                                                        pagef.to}>
                                                                        {pagef.name}
                                                                    </Link>
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Menu>
                                            ) : (
                                                ""
                                            )
                                        }
                                    </MenuItem>
                                })}
                            </Menu>
                            <button
                                aria-haspopup={true}
                                role="button"
                                aria-controls={open4 ? "basic-menu" : undefined}
                                aria-expanded={open4 ? "true" : "false"}
                                onClick={(e) => {
                                    handleClick(e, setAnchorEl4);
                                }}
                                className={"hover:text-[#df5b23] cursor-pointer"}>
                                SEZIONI
                            </button>
                            <Menu
                                MenuListProps={{ disablePadding: true }}
                                anchorEl={anchorEl4}
                                open={open4}
                                onClose={() => {
                                    handleClose(setAnchorEl4);
                                }}>
                                {departments && departments.data && departments.data.data
                                    ? departments.data.data.map((department, index) => {
                                        let indexDep = index - 1;

                                        if (department.name === "Nazionale") {
                                            return "";
                                        }
                                        let pages = createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[department.id] ? listPages.data.data[department.id] : [])
                                        let open7Result = open7()
                                        return (
                                            // MENU CON LE SEZIONI
                                            <MenuItem
                                                tabIndex={0}
                                                role="menuitem"
                                                key={index}
                                                sx={pages.length > 0 ?
                                                    {
                                                        padding: 0,
                                                        position: "relative",
                                                        "&:hover": {
                                                            backgroundColor: "#df5b23",
                                                        },

                                                        "&:hover > div": {
                                                            color: "white",
                                                        },
                                                        "&:focus": {
                                                            backgroundColor: "#df5b23",
                                                        },

                                                        "&:focus > div": {
                                                            color: "white",
                                                        },
                                                    }
                                                    :
                                                    {
                                                        backgroundColor: "#ffffff",
                                                        padding: 0,
                                                        ":hover": {
                                                            backgroundColor: "#df5b23"
                                                        },
                                                        "a": {
                                                            color: "#495054",
                                                            padding: "6px 16px"
                                                        },
                                                        ":hover a": {
                                                            color: "#ffffff"
                                                        },
                                                        ":focus": {
                                                            backgroundColor: "#df5b23"
                                                        },
                                                        ":focus a": {
                                                            color: "#ffffff"
                                                        }
                                                    }
                                                }>
                                                {/* le sezioni hanno dei figli? */}
                                                <div
                                                    role={pages.length > 0 ? "button" : 'link'}
                                                    aria-haspopup={true}
                                                    aria-controls={
                                                        open7Result[index] ? "basic-menu" : undefined
                                                    }
                                                    aria-expanded={open7Result[index] ? "true" : "false"}
                                                    onClick={(event) => {
                                                        if (pages.length > 0) {
                                                            handleClick(event, setAnchorEl7, anchorEl7)
                                                        }
                                                    }}
                                                    id={"sezione"}
                                                    className={
                                                        `!bg-transparent w-full ${pages.length > 0 ? 'pl-[16px] pr-[16px] pb-[6px] pt-[6px]' : ''} flex items-center justify-between`
                                                    }>
                                                    {pages.length > 0 ?
                                                        (department.name.includes("Sezione")
                                                            ? department.name.substring(8)
                                                            : department.name) :
                                                        <Link className="w-full" to={"/sezioni/" + department.name}>
                                                            {department.name.includes("Sezione")
                                                                ? department.name.substring(8)
                                                                : department.name}
                                                        </Link>
                                                    }
                                                    {pages.length > 0 ? <KeyboardArrowRightIcon /> : ""}
                                                </div>
                                                {/* Se hanno figli li facciamo vedere */}
                                                {
                                                    pages.length > 0 ? (
                                                        <Menu
                                                            role="menu"
                                                            id="basic-menu"
                                                            MenuListProps={{ disablePadding: true }}
                                                            anchorEl={
                                                                anchorEl7 && anchorEl7.length > 0
                                                                    ? anchorEl7[index].anchor
                                                                    : null
                                                            }
                                                            open={
                                                                open7Result && open7Result.length > 0
                                                                    ? open7Result[index].open
                                                                    : false
                                                            }
                                                            onClose={() =>
                                                                handleClose(setAnchorEl7, anchorEl7)
                                                            }
                                                            sx={{
                                                                position: "absolute",
                                                                left: () => {
                                                                    return document.getElementById("sezione")
                                                                        ? getComputedStyle(
                                                                            document.getElementById("sezione")
                                                                        ).width
                                                                        : "0px";
                                                                },
                                                                top: "-35px",
                                                            }}>
                                                            <MenuItem
                                                                role="menuitem"
                                                                key={index}
                                                                sx={{
                                                                    backgroundColor: "#ffffff",
                                                                    padding: 0,
                                                                    ":hover": {
                                                                        backgroundColor: "#df5b23"
                                                                    },
                                                                    "a": {
                                                                        color: "#495054",
                                                                        padding: "6px 16px"
                                                                    },
                                                                    ":hover a": {
                                                                        color: "#ffffff"
                                                                    },
                                                                    ":focus": {
                                                                        backgroundColor: "#df5b23"
                                                                    },
                                                                    ":focus a": {
                                                                        color: "#ffffff"
                                                                    }
                                                                }}
                                                                tabIndex={0}
                                                            >
                                                                <Link
                                                                    onClick={() => {
                                                                        setAnchorEl7((prevState) => {
                                                                            return anchorsEl7SetNull(prevState)
                                                                        });
                                                                        setAnchorEl4(null);
                                                                    }} className="w-full" to={"/sezioni/" + department.name}>
                                                                    Dettagli della sezione
                                                                </Link>
                                                            </MenuItem>
                                                            {pages && pages.map((page, index) => {

                                                                const open8Result = open8()
                                                                // MENU PAGINA FIGLIA DELLE SEZIONI
                                                                return (
                                                                    <MenuItem
                                                                        role="menuitem"
                                                                        tabIndex={0}
                                                                        key={index + `${index}`}
                                                                        sx={page.pages.length > 0 ?
                                                                            {
                                                                                padding: 0,
                                                                                position: "relative",
                                                                                "&:hover": {
                                                                                    backgroundColor: "#df5b23",
                                                                                },

                                                                                "&:hover > div": {
                                                                                    color: "white",
                                                                                },
                                                                                "&:focus": {
                                                                                    backgroundColor: "#df5b23",
                                                                                },

                                                                                "&:focus > div": {
                                                                                    color: "white",
                                                                                },
                                                                            }
                                                                            :
                                                                            {
                                                                                backgroundColor: "#ffffff",
                                                                                padding: 0,
                                                                                ":hover": {
                                                                                    backgroundColor: "#df5b23"
                                                                                },
                                                                                "a": {
                                                                                    color: "#495054",
                                                                                    padding: "6px 16px"
                                                                                },
                                                                                ":hover a": {
                                                                                    color: "#ffffff"
                                                                                },
                                                                                ":focus": {
                                                                                    backgroundColor: "#df5b23"
                                                                                },
                                                                                ":focus a": {
                                                                                    color: "#ffffff"
                                                                                }
                                                                            }
                                                                        }>
                                                                        <div
                                                                            role="button"
                                                                            aria-haspopup={true}
                                                                            aria-controls={
                                                                                open8Result[index]
                                                                                    ? "basic-menu"
                                                                                    : undefined
                                                                            }
                                                                            aria-expanded={
                                                                                open8Result[index] ? "true" : undefined
                                                                            }
                                                                            onClick={(event) => {

                                                                                if (page.pages.length > 0) {
                                                                                    handleClick(
                                                                                        event,
                                                                                        setAnchorEl8,
                                                                                        anchorEl8,
                                                                                        indexDep,
                                                                                        index
                                                                                    )

                                                                                }
                                                                            }
                                                                            }
                                                                            id={"sezione-pages"}
                                                                            className={
                                                                                `!bg-transparent w-full ${page.pages.length > 0 ? 'pl-[16px] pr-[16px] pb-[6px] pt-[6px]' : ''} flex items-center justify-between`
                                                                            }>
                                                                            {page.pages.length > 0 ? page.name :
                                                                                <Link className="w-full" to={"/sezioni/" +
                                                                                    department.name +
                                                                                    page.to}>
                                                                                    {page.name}
                                                                                </Link>
                                                                            }
                                                                            {page.pages.length > 0 ? (
                                                                                <KeyboardArrowRightIcon />
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                        {page.pages.length > 0 ? (
                                                                            <Menu
                                                                                MenuListProps={{ disablePadding: true }}
                                                                                anchorEl={() => {
                                                                                    let value = null
                                                                                    if (anchorEl8 &&
                                                                                        anchorEl8.length > 0 &&
                                                                                        anchorEl8[indexDep].length > 0 &&
                                                                                        anchorEl8[indexDep][index].length > 0
                                                                                    ) {
                                                                                        anchorEl8[indexDep][index].map((anchor) => {
                                                                                            if (anchor.anchor !== null) {
                                                                                                value = anchor.anchor
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    return value
                                                                                }}
                                                                                open={
                                                                                    open8Result &&
                                                                                        open8Result.length > 0 &&
                                                                                        open8Result[indexDep].length > 0
                                                                                        ? open8Result[indexDep][index].open
                                                                                        : false
                                                                                }
                                                                                onClose={() => {
                                                                                    handleClose(
                                                                                        setAnchorEl8,
                                                                                        anchorEl8,
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    position: "absolute",
                                                                                    left: () => {
                                                                                        return document.getElementById(
                                                                                            "sezione-pages"
                                                                                        )
                                                                                            ? getComputedStyle(
                                                                                                document.getElementById(
                                                                                                    "sezione-pages"
                                                                                                )
                                                                                            ).width
                                                                                            : "0px";
                                                                                    },
                                                                                    top: "-35px",
                                                                                }}>
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    sx={{
                                                                                        backgroundColor: "#ffffff",
                                                                                        padding: 0,
                                                                                        ":hover": {
                                                                                            backgroundColor: "#df5b23"
                                                                                        },
                                                                                        "a": {
                                                                                            color: "#495054",
                                                                                            padding: "6px 16px"
                                                                                        },
                                                                                        ":hover a": {
                                                                                            color: "#ffffff"
                                                                                        },
                                                                                        ":focus": {
                                                                                            backgroundColor: "#df5b23"
                                                                                        },
                                                                                        ":focus a": {
                                                                                            color: "#ffffff"
                                                                                        }
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setAnchorEl8((prevState) => {
                                                                                            return anchorsEl8SetNull(prevState)
                                                                                        });
                                                                                        setAnchorEl7((prevState) => {
                                                                                            return anchorsEl7SetNull(prevState)
                                                                                        });
                                                                                        setAnchorEl4(null);
                                                                                    }}>
                                                                                    <Link className="w-full" to={"/sezioni/" +
                                                                                        department.name +
                                                                                        page.to}>
                                                                                        {page.name}
                                                                                    </Link>
                                                                                </MenuItem>
                                                                                {page.pages.length > 0
                                                                                    ? page.pages.map((pagef, index) => {
                                                                                        // MENU PAGINE FILGLIE DELLE PAGINE FIGLIE
                                                                                        return (
                                                                                            <MenuItem
                                                                                                key={index + `${index}`}
                                                                                                sx={{
                                                                                                    backgroundColor: "#ffffff",
                                                                                                    padding: 0,
                                                                                                    ":hover": {
                                                                                                        backgroundColor: "#df5b23"
                                                                                                    },
                                                                                                    "a": {
                                                                                                        color: "#495054",
                                                                                                        padding: "6px 16px"
                                                                                                    },
                                                                                                    ":hover a": {
                                                                                                        color: "#ffffff"
                                                                                                    },
                                                                                                    ":focus": {
                                                                                                        backgroundColor: "#df5b23"
                                                                                                    },
                                                                                                    ":focus a": {
                                                                                                        color: "#ffffff"
                                                                                                    }
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    setAnchorEl8((prevState) => {
                                                                                                        let result = anchorsEl8SetNull(prevState)
                                                                                                        return result
                                                                                                    });
                                                                                                    setAnchorEl7((prevState) => {
                                                                                                        let result = anchorsEl7SetNull(prevState)
                                                                                                        return result
                                                                                                    });
                                                                                                    setAnchorEl4(null);
                                                                                                }}>
                                                                                                <Link className="w-full" to={"/sezioni/" +
                                                                                                    department.name +
                                                                                                    page.to +
                                                                                                    pagef.to}>
                                                                                                    {pagef.name}
                                                                                                </Link>
                                                                                            </MenuItem>
                                                                                        );
                                                                                    })
                                                                                    : ""}
                                                                            </Menu>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Menu>
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                            </MenuItem>
                                        );
                                    })
                                    : ""}
                            </Menu>
                            <Link to={'/trek-magazine'}
                                className={"hover:text-[#df5b23] cursor-pointer"}>
                                TREK MAGAZINE
                            </Link>
                            <Link to={'/cataloghi'}
                                className={"hover:text-[#df5b23] cursor-pointer"}>
                                CATALOGHI
                            </Link>
                            <Link to={'/news'}
                                className={"hover:text-[#df5b23] cursor-pointer"}>
                                NEWS
                            </Link>
                            <Link to={'/eventi'}
                                className={"hover:text-[#df5b23] cursor-pointer"}>
                                EVENTI
                            </Link>
                            <Link to={'/corsi'}
                                className={"hover:text-[#df5b23] cursor-pointer"}>
                                CORSI
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* RINNOVA TESSERA */}
            <DialogCustom
                title={"Rinnova tessera"}
                fullScreen={false}
                open={openRinnova}
                handleClose={() => {
                    if (
                        formik.values.assoc_type.name === "Sostenitore" &&
                        formik.values.assoc_type.value < 40
                    ) {
                    } else {
                        setOpenRinnova(false);
                        setOpenPaymentMethod(true);
                    }
                }}
                closeModal={() => setOpenRinnova(false)}
                content={
                    <form>
                        <fieldset className="flex items-center justify-center gap-2">
                            <legend></legend>
                            <InputCustom
                                select
                                fullWidth
                                title={"Tipo di associazione*"}
                                name={"assoc_type"}
                                value={formik.values.assoc_type.name}
                                onChange={(e) => {
                                    let value = assocType && assocType.filter(assoc => assoc.name === e.target.value) && assocType.filter(assoc => assoc.name === e.target.value)[0] && assocType.filter(assoc => assoc.name === e.target.value)[0].value
                                    let id = assocType && assocType.filter(assoc => assoc.name === e.target.value) && assocType.filter(assoc => assoc.name === e.target.value)[0] && assocType.filter(assoc => assoc.name === e.target.value)[0].id
                                    setErrorAssocValue(false);
                                    formik.setFieldValue("assoc_type", {
                                        name: e.target.value,
                                        value: Number(value),
                                        id: id
                                    });
                                }}>
                                <MenuItem
                                    value={'-'}
                                    key={0}>
                                    -
                                </MenuItem>
                                {assocType.map((assoc, index) => {
                                    return (
                                        <MenuItem value={assoc.name} key={index + 1}>
                                            {assoc.name}
                                        </MenuItem>
                                    );
                                })}
                            </InputCustom>
                            <div>
                                <InputCustom
                                    classcontainer={"w-auto"}
                                    disabled={formik.values.assoc_type.name !== "Sostenitore"}
                                    title={formik.values.assoc_type.name === "Sostenitore" ? "a partire da*" : "Importo richiesto*"}
                                    value={formik.values.assoc_type.value}
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min:
                                                formik.values.assoc_type.name === "Sostenitore"
                                                    ? 40
                                                    : formik.values.assoc_type.value,
                                        },
                                    }}
                                    onChange={(e) => {
                                        if (
                                            formik.values.assoc_type.name === "Sostenitore" &&
                                            e.target.value < 40
                                        ) {
                                            setErrorAssocValue(true);
                                        } else {
                                            setErrorAssocValue(false);
                                        }
                                        formik.setFieldValue("assoc_type", {
                                            ...formik.values.assoc_type,
                                            value: e.target.value,
                                        });
                                    }}
                                />
                                {errorAssocValue && (
                                    <InputBilo
                                        className={"text-[#ff0000]"}
                                        title={
                                            `Il tipo associzione "Sostenitore" prevede una quota minima di 40€`
                                        }
                                    />
                                )}
                            </div>
                        </fieldset>
                    </form>
                }
                button={"PROCEDI"}
                buttonDisabled={(formik.values.assoc_type.name === '-') || (formik.values.assoc_type.name === 'Sostenitore' && formik.values.assoc_type.value < 40)}
            />
            {/*PAGAMENTO RINNOVO*/}
            <DialogCustom
                open={openPaymentMethod}
                title={"Scegli"}
                content={
                    <>
                        <InputBilo
                            className={"text-2xl"}
                            title={`Tipo di associazione: ${formik.values.assoc_type.name}`}
                        />
                        <InputBilo
                            className={"text-2xl"}
                            title={`Importo: €${formik.values.assoc_type.value}`}
                        />
                        <InputBilo
                            className={"text-xl"}
                            title={
                                env.borsellinoFunction ? "Preferisci pagare con il borsellino elettronico o con Carta?" : 'Con cosa vuoi pagare?'
                            }
                        />
                    </>
                }
                handleClose={() => {
                    api
                        .post(
                            `users/${count.profile.id}/borsellino/prepareWalletAssociation`,
                            {
                                user_id: count.profile.id,
                                association_type_id: formik.values.assoc_type.id,
                                price: formik.values.assoc_type.value,
                            }
                        )
                        .then((res) => {
                            localStorage.setItem("lastpage", location.pathname);
                            if (res && res.data && res.data.data) {
                                navigate("/payment-success");
                            } else {
                                setErrorAssoc({
                                    open: true,
                                    error:
                                        res && res.data && res.data.errors
                                            ? res.data.errors[Object.keys(res.data.errors)[0]][0]
                                            : "",
                                });
                            }
                            return res;
                        });
                    setOpenPaymentMethod(false);
                }}
                closeModal={() => {
                    setOpenPaymentMethod(false);
                }}
                handleClose2={() => {
                    api
                        .post(`users/${count.profile.id}/associationWithoutWallet`, {
                            user_id: count.profile.id,
                            association_type_id: formik.values.assoc_type.id,
                            price: formik.values.assoc_type.value,
                        })
                        .then((res) => {
                            localStorage.setItem("lastpage", location.pathname);
                            if (res && res.data && res.data.data) {
                                window.open(res.data.data);
                            } else {
                                setErrorAssoc({
                                    open: true,
                                    error:
                                        res && res.data && res.data.errors
                                            ? res.data.errors[Object.keys(res.data.errors)[0]][0]
                                            : "",
                                });
                            }
                            return res;
                        });

                    setOpenPaymentMethod(false);
                }}
                button={env.borsellinoFunction ? "Borsellino elettronico" : null}
                button2={"Carta"}
            />
            {/* ERROR PAGAMENTO */}
            <DialogCustom
                open={errorAssoc.open}
                title={"Errore"}
                content={
                    <>
                        <InputBilo className={"text-xl"} title={errorAssoc.error} />
                    </>
                }
                button={"Chiudi"}
                handleClose={() => {
                    setErrorAssoc((prevState) => {
                        return {
                            open: false,
                            error: "",
                        };
                    });
                }}
                closeModal={() => {
                    setErrorAssoc((prevState) => {
                        return {
                            open: false,
                            error: "",
                        };
                    });
                }}
            />
        </AppBar >
    );
};

const mapState = (state) => ({
    count: state.count,
});

export default connect(mapState)(Topbar);
