import React from 'react'
import LandscapeIcon from "@mui/icons-material/Landscape";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import { Rating } from '@mui/material';

const customColor = [
    {
        linearGradient: <linearGradient id="linearColors0" x1={0} y1={0} x2={1} y2={0}>
            <stop offset={0} stopColor='#2ed610' />
            <stop offset={1} stopColor='#2ed610' />
        </linearGradient>
    },
    {
        linearGradient: <linearGradient id="linearColors1" x1={0} y1={0} x2={1} y2={0}>
            <stop offset={0} stopColor='#2ed610' />
            <stop offset={1} stopColor='#d5a110' />
        </linearGradient>,
    },
    {
        linearGradient: <linearGradient id="linearColors2" x1={0} y1={0} x2={1} y2={0}>
            <stop offset={0} stopColor='#d5a110' />
            <stop offset={1} stopColor='#d5a110' />
        </linearGradient>,
    },
    {
        linearGradient: <linearGradient id="linearColors3" x1={0} y1={0} x2={1} y2={0}>
            <stop offset={0} stopColor='#d5a110' />
            <stop offset={1} stopColor='#d52a10' />
        </linearGradient>,
    },
    {
        linearGradient: <linearGradient id="linearColors4" x1={0} y1={0} x2={1} y2={0}>
            <stop offset={0} stopColor='#d52a10' />
            <stop offset={1} stopColor='#d52a10' />
        </linearGradient>,
    },
    {
        linearGradient: <linearGradient id="linearColors5" x1={0} y1={0} x2={1} y2={0}>
            <stop offset={0} stopColor='#d52a10' />
            <stop offset={1} stopColor='#d52a10' />
        </linearGradient>,
    },
]

const filterName = [
    'Facile',
    'Facile/Medio',
    'Medio',
    'Medio/Impegnativo',
    'Impegnativo',
    'Impegnativo+',
]

function RatingCustom(props) {
    
    return (
        <div className='flex items-center'>
            {[...new Array(props.max)].map((value, index) => {
                return (
                    <div key={index} className='flex flex-col'>
                        {/* <div>{customColor[index].color1} {customColor[index].color2}</div> */}
                        <Rating
                            onChange={(e)=>{
                                props.onChange(index)
                            }}
                            precision={1}
                            name={"rating" + index}
                            value={props.value ? props.value.includes(index) : false}
                            max={1}
                            size="large"
                            getLabelText={(value)=>{
                                return `${index + 1} stell${index + 1 !== 1 ? 'e' : 'a'}`;
                            }}
                            icon={
                                <div>
                                    <svg width={0} height={0}>
                                        {customColor[index].linearGradient}
                                    </svg>
                                    <LandscapeIcon
                                        sx={{
                                            maxWidth: props.mobile ? 35 : 50,
                                            maxHeight: props.mobile ? 35 : 50,
                                            width: "100%",
                                            height: "100%",
                                            minWidth: 25,
                                            minHeight: 25,
                                            fill: props.mobile ? '#df5b23' : `url(#linearColors${index})`
                                        }}
                                        fontSize="inherit"
                                    />
                                </div>
                            }
                            emptyIcon={
                                <LandscapeOutlinedIcon
                                    sx={{
                                        maxWidth: props.mobile ? 35 : 50,
                                        maxHeight: props.mobile ? 35 : 50,
                                        width: "100%",
                                        height: "100%",
                                        minWidth: 25,
                                        minHeight: 25,
                                    }}
                                    style={{ opacity: 0.55 }}
                                    fontSize="inherit"
                                />
                            }
                        />
                    </div>
                );
            })}
        </div>
    )
}

export default RatingCustom