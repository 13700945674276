import React from "react";
import calendario from "../assets/img/icona-calendario-home.png";
import arrow from "../assets/img/freccia-home.png";
import difficolta from "../assets/img/icona-difficolta-home-piena.png";
import difficolta2 from "../assets/img/icona-difficolta-home-trasp.png";
import noImage from "../assets/img/no_image.jpg";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import InputBilo from "./InputBilo";

const Trek = ({ img, index, res, maps, close, }) => {
    const navigate = useNavigate();

    return (
        <div
            className={`shadow-2xl flex flex-col rounded-lg m-2 md:m-4 w-full ${!maps && "min-w-[260px] max-w-[550px] max-h-[600px]"
                } ${maps && "max-w-[362px] w-[18vw] min-w-[260px]"}`}
            style={{ flexBasis: "calc(33% - 4rem)" }}>
            <div className={`${!maps && "relative h-full"}`}>
                {!maps ? (
                    <>
                        <div
                            className={`absolute top-0 left-5 text-white text-xl px-4 font-bold  rounded-b-lg ${res.type_trek === "Grande Trek"
                                ? "bg-[#183043]"
                                : res.type_trek === "Giornaliero"
                                    ? "bg-[#ec9518]"
                                    : "bg-[#b0af3d]"
                                }`}>
                            {res.type_trek}
                        </div>
                        <img
                            src={res.image_path ? res.image_path : noImage}
                            alt={res.title}
                            className={"object-cover rounded-t-xl max-h-[300px]  h-full w-full"}
                        />
                    </>
                ) : (
                    <>
                        <div
                            className={
                                (res.formatName === "Grande Trek"
                                    ? "bg-[#183043]"
                                    : res.formatName === "Giornaliero"
                                        ? "bg-[#ec9518]"
                                        : "bg-[#b0af3d]") +
                                " rounded-t-xl p-2 text-white flex flex-row justify-between items-center"
                            }>
                            <InputBilo className={"uppercase"} title={res.formatName} />
                            <button
                                className="text-2xl"
                                onClick={close}
                            >
                                x
                            </button>
                        </div>
                        <img
                            src={res.image_path ? res.image_path : noImage}
                            alt={res.title}
                            className={"object-cover  max-h-[300px]  h-full w-full"}
                        />
                    </>
                )}
                <div
                    className={`${!maps ? "bg-black bg-opacity-40 absolute " : "bg-[#a49a93]"
                        }  w-full inset-x-0 bottom-0`}>
                    <div
                        className={`flex flex-col items-end ${!maps ? "justify-center" : "w-full justify-end"
                            } p-2`}>
                        <div className={"text-white md:text-lg text-[3vw]"}>
                            Livello di difficoltà
                        </div>
                        <span className="absolute text-transparent">
                            {res.order}
                        </span>
                        <div className={"flex flex-row items-center gap-2"}>
                            {[...new Array(6)].map((resMap, indexMap) => {
                                return (
                                    <React.Fragment key={indexMap} >
                                        <img
                                            aria-hidden="true"
                                            className={"object-contain w-6 h-6"}
                                            alt={`difficoltà ${indexMap + 1} ${indexMap + 1 <= res.order ? ' attivo' : ' disattivo'}`}
                                            src={indexMap + 1 <= res.order ? difficolta : difficolta2}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={
                    "bg-[#34291f] text-white relative md:text-lg text-[3vw] px-2"
                }>
                {maps ? (
                    <div className={"uppercase inline-flex"}>{'Sez. ' + res.dname === 'PIEMONTE E VALLE D\'AOSTA' ? 'PIEMONTE-VAL D\'AOSTA' : res.dname}</div>
                ) : (
                    <>
                        <div className={"uppercase inline-flex"}>{'Sez. ' + (res.departments ? (res.departments === 'PIEMONTE E VALLE D\'AOSTA' ? 'PIEMONTE-VAL D\'AOSTA' : res.departments) : (res.nome === 'PIEMONTE E VALLE D\'AOSTA' ? 'PIEMONTE-VAL D\'AOSTA' : res.nome))}</div>
                    </>
                )}
            </div>
            <div
                className={
                    "bg-[#e2d5ca] text-[#34291f] md:text-lg text-[3vw] px-2 py-1 flex flex-row gap-2"
                }>
                {!maps && (
                    <img
                        alt={"Calendario"}
                        src={calendario}
                        className={"object-contain w-12 h-10 m-2"}
                    />
                )}
                <div className={`flex ${!maps ? "flex-col" : "flex-row"}`}>
                    <div
                        className={
                            "inline-flex gap-2 text-[15px] md:text-base min-[1600px]:!text-xl"
                        }>
                        Partenza{" "}
                        <div className={"font-bold text-[18px] min-[1600px]:text-2xl"}>
                            {moment(res.date_start_schedulation).format("DD/MM/YYYY")}
                        </div>
                    </div>
                    {maps && <div className="md:text-xl text-[3vw] mx-2">-</div>}
                    <div
                        className={
                            "text-[11px] md:text-base min-[1600px]:!text-xl font-bold"
                        }>
                        Durata {maps ? res.trek_duration_day : res.duration} giorn{maps ? (Number(res.trek_duration_day) > 1 ? 'i' : 'o') : (Number(res.duration) > 1 ? 'i' : 'o')}
                    </div>
                </div>
            </div>
            <h1
                className={`text-[#34291f] text-xl p-3 bg-[#f0e9e3] font-bold ${res.title ? "min-h-[100px] max-h-[100px]" : ""
                    } flex items-center`}>
                {res.title
                    ? res.title.length > 67
                        ? res.title.slice(0, 67) + "..."
                        : res.title
                    : ""}
            </h1>
            <Link to={"/trek/" + res['internal_id']} aria-label={`${res?.labelEtichetta ? res?.labelEtichetta : 'Scopri'} apre in una nuova scheda il dettaglio del trek ${res.title}`} target="_blank"
                className={`flex flex-row items-center justify-between p-2 rounded-b-xl px-4 cursor-pointer hover:opacity-75 ${res.type_trek === "Grande Trek" || res.formatName === "Grande Trek"
                    ? "bg-[#183043]"
                    : res.type_trek === "Giornaliero" ||
                        res.formatName === "Giornaliero"
                        ? "bg-[#ec9518]"
                        : "bg-[#b0af3d]"
                    }`}>
                <div className={`text-2xl whitespace-nowrap font-bold h-[30px] text-white`}>
                    {res.labelEtichetta
                        ? res.labelEtichetta.length > 14
                            ? res.labelEtichetta.slice(0, 14) + "..."
                            : res.labelEtichetta
                        : "SCOPRI"}
                </div>
                <img alt={"freccia"} src={arrow} className={"object-contain w-6 h-6 "} />
            </Link>
        </div>
    );
};

export default Trek;
