import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from '../Sezioni/FooterTop';
import FooterDown from '../Sezioni/FooterDown';
import InputBilo from '../components/InputBilo';
import InputGimlet from '../components/InputGimlet';
import { Link } from 'react-router-dom';
import env from '../conf/variables';

function LinkAssicurazioni({title, fileName}){
    return (
        <div className='mt-4'>
            <Link className='underline' to={`${env.url_api_without_api}document/${fileName}`} target='_blank'>{title}</Link>
        </div>
    )

}

function CopertureAssicurative() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Coperture assicurative"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div className={"font-bold"}>Coperture assicurative</div>
                    </div>
                    {/* Content */}
                    <InputGimlet
                        title={'Coperture assicurative'}
                        fontWeight={500}
                        fontStyle="italic"
                        className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                    />
                    <div className="mb-5 text-xl">
                        <span>
                        Qui sotto potrete trovare testo dell’assicurazione. Moduli di denuncia dell’infortunio. In caso di denuncia infortunio vi chiediamo di scaricare e compilare i moduli e attenervi alle normative presenti nell’ultimo capitolo Assicurazione
                        COSA FARE IN CASO DI EVENTO INFORTUNISTICO
                        </span>
                        
                        <LinkAssicurazioni fileName='assicurazione_sito.pdf' title='Assicurazione sito'/>
                        <LinkAssicurazioni fileName='denuncia_sinistro_inf_trekkingitalia_socio.pdf' title='Denuncia sinistro infortunio socio'/>
                        <LinkAssicurazioni fileName='denuncia_sinistro_inf_trekkingitalia_socio_volontario.pdf' title='Denuncia sinistro infortunio socio volontario'/>
                    </div>

                </div>


            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default CopertureAssicurative